import React, { createContext, useState } from "react";


const RegisterContext = createContext();

const isBrowser = () => typeof window !== "undefined";

export const RegisterProvider = ({children}) => {
    const [activeStep, setActiveStep] = useState(0);
    const [registerFormData, setRegisterFormData] = useState({
        personal: {
            first_name: '',
            last_name: '',
            email: '',
            password: ''
        },
        health: {
            weight: '',
            age: '',
            gender: ''
        },
        more: {
            goals: [],
            describe: ''
        }
    })

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    function updateFormData(stepName, data) {
        setRegisterFormData({
            ...registerFormData,
            [stepName]: data
        })
    }

    async function postRegistration() {
       
        var postRegistrationHeaders = new Headers();
        postRegistrationHeaders.append("Content-Type", "application/json");
        

        

        var userRegistration = {
            method: 'POST',
            headers: postRegistrationHeaders,
            body: JSON.stringify(registerFormData)
        };

        let RegistrationResult;

        try {
            const registrationR = await fetch("https://dev-beachmonkeyz-be.azurewebsites.net/api/register", userRegistration);
            
            if(registrationR.status === 201) {
                RegistrationResult =  await registrationR.json();
                console.log('ashhh reg res', RegistrationResult)
                
            } else {
                setActiveStep(0);
                return false;
            }

        } catch (error) {
            console.error(error);
        }

    }

    return (
        <RegisterContext.Provider value={{activeStep, handleNext, handleBack, registerFormData, updateFormData, postRegistration}}>
            {children}
        </RegisterContext.Provider>
    )
}

export default RegisterContext;