import { Button, Container, Grid, TextField, MenuItem } from '@material-ui/core';
import React, { useContext, useState } from 'react'
import styles from '../assets/scss/edit-profile.module.scss'
import RegisterContext from '../providers/register'
import GridRow from './_grid-row';
import UserContext from '../providers/user-provider'
import { Link } from 'gatsby';


export default function EditProfile() {
    const { user } = useContext(UserContext);
    // const [registerFormData, setRegisterFormData] = useState({
    //     personal: {
    //         full_name: '',
    //         email: '',
    //         password: '',
    //         age: ''
    //     },
    //     health: {
    //         weight: '',
    //         age: '',
    //         gender: ''
    //     },
    //     more: {
    //         goals: [],
    //         describe: ''
    //     }
    // })

    const [invalidForm, setInvalidForm] = useState(true);
    const personal = user.personal;

    const [formDirtyFields, setFormDirtyFields] = useState({
        first_name: false,
        last_name: false,
        email: false,
        password: false,
        repeat_password: false,
        age: false,
        goal: false,
        fitness_level: false,
    });

    const helperText = {
        first_name: 'Please enter your first name.',
        last_name: 'Please enter your last name.',
        email: 'Please enter a valid email.',
        password: 'Password enter a password.',
        repeat_password: 'Password doesn\'t match.',
        age: 'Please enter your age.',
        goal: 'Please select your goal',
        fitness_level: 'Please select your fitness level.'
    }



    function handleTextField(e) {
        const name = e.target.name;
        const value = e.target.value || e.target.defaultValue;
        const type = e.target.type;
        console.log('ashh val', value)
        if (
            value === undefined ||
            value == '' ||
            (type == 'email' && !isValidEmail(value)) ||
            (type == 'password' && !isValidPassword(value)) ||
            (name == 'repeat_password' && !isPasswordMatch(value))
        ) {
            setInvalidForm(true);
            setFormDirtyFields({ ...formDirtyFields, [name]: true });

        } else {
            setInvalidForm(false);
            setFormDirtyFields({ ...formDirtyFields, [name]: false });
            personal[name] = value;

        }


        if (type == "password" && !isPasswordMatch(value)) {
            setFormDirtyFields({ ...formDirtyFields, repeat_password: true });
            setInvalidForm(true);
        }
    }

    function isValidEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    function isValidPassword(pass) {
        if (pass.length < 8) {
            return false;
        }

        return true;
    }

    function isPasswordMatch(pass) {
        if (pass === personal.password) {
            return true
        }

        return false
    }

    function submit(e) {
        e.preventDefault();

        let isFormValid = false;
        for (const key in formDirtyFields) {
            if (Object.hasOwnProperty.call(formDirtyFields, key)) {
                const fieldValidation = formDirtyFields[key];
                if (fieldValidation || personal[key] == undefined || personal[key] == '') {
                    isFormValid = false;
                    setFormDirtyFields({ ...formDirtyFields, [key]: true });
                    break;
                }
            }

            isFormValid = true;
        }

        if (isFormValid) {
            // updateFormData('personal', personal);
            // handleNext();
        }


        return false;
    }

    return (
        <React.Fragment>
            <div className={[styles.wrapper, "editProfile"].join(" ")}>
                <Container maxWidth="xl">
                    <GridRow>
                        <Grid container spacing={3} direction="row-reverse">

                            <Grid item xs={12}>
                                <form noValidate autoComplete="off" onSubmit={submit}>
                                    <div className={styles.formField}>
                                        <TextField
                                            label="First Name"
                                            defaultValue={personal ? personal.first_name : ""}
                                            variant="outlined"
                                            name="first_name"
                                            classes={{ root: styles.input }}
                                            onChange={handleTextField}
                                            error={formDirtyFields.first_name}
                                            helperText={formDirtyFields.first_name ? helperText.first_name : ''}
                                            required
                                        />
                                    </div>

                                    <div className={styles.formField}>
                                        <TextField
                                            label="Last Name"
                                            defaultValue={personal ? personal.last_name : ""}
                                            variant="outlined"
                                            name="last_name"
                                            classes={{ root: styles.input }}
                                            onChange={handleTextField}
                                            error={formDirtyFields.last_name}
                                            helperText={formDirtyFields.last_name ? helperText.last_name : ''}
                                            required
                                        />
                                    </div>

                                    <div className={styles.formField}>
                                        <TextField
                                            label="E-mail"
                                            defaultValue={personal ? personal.email : ""}
                                            type="email"
                                            name="email"
                                            variant="outlined"
                                            classes={{ root: styles.input }}
                                            onChange={handleTextField}
                                            error={formDirtyFields.email}
                                            helperText={formDirtyFields.email ? helperText.email : ''}
                                            required />
                                    </div>
                                    <div className={styles.formField}>
                                        <TextField
                                            label="Age"
                                            defaultValue={user ? user.health.age : ""}
                                            name="age"
                                            variant="outlined"
                                            classes={{ root: styles.input }}
                                            onChange={handleTextField}
                                            error={formDirtyFields.age}
                                            helperText={formDirtyFields.age ? helperText.age : ''}
                                            required />
                                    </div>
                                    <div className={styles.formField}>
                                        <TextField
                                            select
                                            defaultValue={user ? user.more.goals[0] : ""}
                                            label="Goal"
                                            variant="outlined"
                                            name="goal"
                                            classes={{ root: styles.input }}
                                            onChange={handleTextField}
                                            error={formDirtyFields.goal}
                                            helperText={formDirtyFields.goal ? helperText.goal : ''}
                                            required
                                        >
                                            <MenuItem value={'Lose some weight'}>
                                                Lose some weight
                                            </MenuItem>
                                            <MenuItem value={'Get toned and maintain my shape'}>
                                                Get toned and maintain my shape
                                            </MenuItem>
                                            <MenuItem value={'Be more flexible'}>
                                                Be more flexible
                                            </MenuItem>
                                            <MenuItem value={'Become stronger'}>
                                                Become stronger
                                            </MenuItem>
                                            <MenuItem value={'Increase my endurance'}>
                                                Increase my endurance
                                            </MenuItem>
                                        </TextField>
                                    </div>

                                    <div className={styles.formField}>
                                        <TextField
                                            select
                                            defaultValue={user ? user.more.describe : ""}
                                            label="Fitness Level"
                                            variant="outlined"
                                            name="fitness_level"
                                            classes={{ root: styles.input }}
                                            onChange={handleTextField}
                                            error={formDirtyFields.fitness_level}
                                            helperText={formDirtyFields.fitness_level ? helperText.fitness_level : ''}
                                            required
                                        >
                                            <MenuItem value={'Novice (beginner)'}>
                                                Novice (beginner)
                                            </MenuItem>
                                            <MenuItem value={'Weekend Warrior (only workout on weekends)'}>
                                                Weekend Warrior (only workout on weekends)
                                            </MenuItem>
                                            <MenuItem value={'Fitness enthusiast'}>
                                                Fitness enthusiast
                                            </MenuItem>
                                            <MenuItem value={'Recreational athlete'}>
                                                Recreational athlete
                                            </MenuItem>
                                            <MenuItem value={'Competitive athlete'}>
                                                Competitive athlete
                                            </MenuItem>
                                        </TextField>
                                    </div>


                                    <div className={styles.formField}>
                                        <Button disabled={invalidForm} variant="contained" color="primary" disableElevation type="submit" classes={{ root: styles.btn }}>Update</Button>

                                        <Link to="/change-password" className={["button", styles.link].join(" ")}>Change Password</Link>

                                    </div>
                                </form>

                            </Grid>
                        </Grid>
                    </GridRow>
                </Container>
            </div>

        </React.Fragment>
    )
}
