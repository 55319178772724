import * as React from "react"
import ClassInternal from "../components/class-internal"
import EditProfile from "../components/edit-profile"
import { Router } from "@reach/router";
import PrivateRoute from "../components/privateRoute"
import Layout from "../components/layout"
import Seo from "../components/seo"


const EditProfilePage = () => (
  <Layout  pageName="Edit Profile">
    <Seo title="Edit Profile" />
    <Router>
      <PrivateRoute path="/edit-profile" component={EditProfile}/>
    </Router>
    
  </Layout>
)

export default EditProfilePage
